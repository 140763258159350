import AuthProvider from "./AuthProvider";
import AlertProvider from "./AlertProvider";
import LoaderProvider from "./LoaderProvider";

function Providers({ children }) {
  return (
    <AuthProvider>
      <AlertProvider>
        <LoaderProvider>{children}</LoaderProvider>
      </AlertProvider>
    </AuthProvider>
  );
}

export default Providers;
