import { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import Loader from "./components/loader";
import AuthRoute from "./auth/AuthRoute";
import "./App.css";
import PrivateRoute from "./auth/PrivateRoute";

const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const Orders = lazy(() => import("./pages/orders"));
const Guidelines = lazy(() => import("./pages/guidelines"));
const Settings = lazy(() => import("./pages/settings"));
const Assets = lazy(() => import("./pages/assets"));
const Invoices = lazy(() => import("./pages/invoices"));
const Analytics = lazy(() => import("./pages/analytics"));
const Organization = lazy(() => import("./pages/organization"));

function App() {
  const { pathname } = useLocation();
  return (
    <>
      <Navbar />
      <div className={`app-content ${pathname === "/login" ? "login" : ""}`}>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Suspense fallback={<Loader show={true} />}>
            <Switch>
              <AuthRoute path="/login" exact>
                <Login />
              </AuthRoute>
              <PrivateRoute path="/home" exact>
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/orders" exact>
                <Orders />
              </PrivateRoute>
              <PrivateRoute path="/guidelines" exact>
                <Guidelines />
              </PrivateRoute>
              <PrivateRoute path="/settings" exact>
                <Settings />
              </PrivateRoute>
              <PrivateRoute path="/assets" exact>
                <Assets />
              </PrivateRoute>
              <PrivateRoute path="/invoices" exact>
                <Invoices />
              </PrivateRoute>
              <PrivateRoute path="/analytics" exact>
                <Analytics />
              </PrivateRoute>
              <PrivateRoute path="/organization" exact>
                <Organization />
              </PrivateRoute>
              <Route path="/">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default App;
