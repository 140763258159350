import { useLocation, NavLink } from "react-router-dom";
import { Container } from "./styles";
import { ReactComponent as DateRangeOutlinedIcon } from "../../assets/icons/ic_calendar.svg";
import { ReactComponent as EqualizerOutlinedIcon } from "../../assets/icons/ic_dashboard.svg";
import { ReactComponent as DescriptionOutlinedIcon } from "../../assets/icons/ic_invoice.svg";
import { ReactComponent as PeopleOutlineOutlinedIcon } from "../../assets/icons/ic_users.svg";
import { ReactComponent as ChatOutlinedIcon } from "../../assets/icons/ic_message.svg";
import { ReactComponent as HelpOutlineOutlinedIcon } from "../../assets/icons/ic_help.svg";
import { ReactComponent as SettingsOutlinedIcon } from "../../assets/icons/ic_setting.svg";
import { ReactComponent as HomeOutlinedIcon } from "../../assets/icons/ic_home.svg";

function Sidebar({ fromTop }) {
  const { pathname } = useLocation();
  if (pathname === "/login") return null;

  return (
    <Container fromTop={fromTop}>
      <NavLink to="/home" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <HomeOutlinedIcon />
          </div>
          <div className="label">Home</div>
        </div>
      </NavLink>
      <NavLink to="/orders" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <DateRangeOutlinedIcon />
          </div>
          <div className="label">Orders</div>
        </div>
      </NavLink>
      <NavLink to="/analytics" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <EqualizerOutlinedIcon />
          </div>
          <div className="label">Analytics</div>
        </div>
      </NavLink>
      <NavLink to="/invoices" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <DescriptionOutlinedIcon />
          </div>
          <div className="label">Invoices</div>
        </div>
      </NavLink>
      <NavLink to="/organization" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <PeopleOutlineOutlinedIcon />
          </div>
          <div className="label">Organization</div>
        </div>
      </NavLink>
      <NavLink to="/assets" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <ChatOutlinedIcon />
          </div>
          <div className="label">Assets</div>
        </div>
      </NavLink>
      <NavLink to="/guidelines" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <HelpOutlineOutlinedIcon />
          </div>
          <div className="label">Guidelines</div>
        </div>
      </NavLink>
      <NavLink to="/settings" className="link" activeClassName="active">
        <div className="link-container">
          <div className="icon">
            <SettingsOutlinedIcon />
          </div>
          <div className="label">Settings</div>
        </div>
      </NavLink>
    </Container>
  );
}

export default Sidebar;
