import { useState } from "react";
import LoaderContext from "../contexts/LoaderContext";
import Loader from "../components/loader";

function LoaderProvider({ children }) {
  const [show, setShow] = useState(false);
  const showLoader = () => setShow(true);
  const hideLoader = () => setShow(false);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      <Loader show={show} />
    </LoaderContext.Provider>
  );
}

export default LoaderProvider;
