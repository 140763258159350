import styled from "styled-components/macro";

export const Container = styled.div`
  width: 14.375rem;
  padding: 2em 1em 0 0;
  // -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.4);
  // -moz-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.4);
  // box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.4);
  font-size: 1.25rem;
  font-family: "Raleway";
  height: calc(100vh - ${(props) => (props.fromTop ? "0px" : "108px")});
  // height: 100%;
  display: flex;
  flex-direction: column;

  .link.active {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: var(--hex-three);
    background-color: rgba(0, 98, 255, 0.05);
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 4px;
      background-color: var(--hex-three);
    }
  }

  .link-container {
    display: flex;
    align-items: center;
    min-height: 52px;
    margin-left: 20px;
  }
  .link {
    text-decoration: none;
    color: var(--hex-six);
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #171725;
    font-weight: 600;
  }
  .link:hover:not(.link.active) {
    color: var(--hex-six);
  }

  .icon {
    margin-right: 20px;
  }
  .link.active .icon > svg > line,
  .link.active .icon > svg > circle,
  .link.active .icon > svg > rect,
  .link.active .icon > svg > path:not(:first-child) {
    stroke: var(--hex-three);
  }
  .link.active .icon > svg > g > g ~ path {
    fill: var(--hex-three);
  }
`;
