import { useState } from "react";
import { useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import ToggleButton from "@mui/material/ToggleButton";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import logo from "../../assets/images/dark-red-black-logo-192.png";
import Sidebar from "../sidebar";
import { Container, UserDetails } from "./styles";
import userImg from "../../assets/icons/ic_users.svg";
import bellIcon from "../../assets/icons/ic-bell.svg";
import msgIcon from "../../assets/icons/ic_message.svg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
//import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import StyledBadge from "./badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../hooks/useAuth";

function Navbar() {
  const { pathname } = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => setShowDrawer(!showDrawer);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container height={pathname === "/login" ? "90px" : "68px"}>
        <div className="brand">
          <img src={logo} alt="logo" />
        </div>
        {!!user && (
          <UserDetails>
            <div className="message">
              <StyledBadge badgeContent={1}>
                <img className="msgIcon" alt="message-icon" src={msgIcon} />
              </StyledBadge>
            </div>
            <div className="notification">
              <img className="bellIcon" alt="bell-icon" src={bellIcon} />
            </div>
            <div className="name">
              <img className="userImage" alt="user" src={userImg} />
              <div className="nameText">{user.name}</div>
              <div className="dropdown-icon">
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ padding: "0" }}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    disableScrollLock={true}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{ top: "25px", marginRight: "10px" }}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My Account</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </UserDetails>
        )}
        {pathname !== "/login" && (
          <div className="burger-icon" onClick={toggleDrawer}>
            <ToggleButton value="justify">
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </div>
        )}
      </Container>
      {pathname !== "/login" && (
        <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer}>
          <Sidebar fromTop={true} />
        </Drawer>
      )}
    </>
  );
}

export default Navbar;
