import ReactDOM from "react-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
} from "@mui/material";
import useAlert from "../../hooks/useAlert";

function Alert() {
  const {
    state: {
      show,
      primaryButton,
      secondaryButton,
      dialogTitle,
      dialogContentText,
    },
    toggleAlert,
  } = useAlert();
  const handleClose = () => toggleAlert();
  if (!show) return null;
  return ReactDOM.createPortal(
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {primaryButton || "OK"}
          </Button>
          {secondaryButton && (
            <Button onClick={handleClose} autoFocus>
              {secondaryButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>,
    document.querySelector("#alert-root")
  );
}

export default Alert;
