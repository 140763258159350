import { Route, Redirect } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function AuthRoute({ children, ...rest }) {
  const { user } = useAuth();
  return !!!user ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to="/home" />
  );
}

export default AuthRoute;
