import { useState } from "react";
import AlertContext from "../contexts/AlertContext";
import Alert from "../components/alert";

const initialState = {
  show: false,
  dialogTitle: "",
  dialogContentText: "",
  primaryButton: "",
  secondaryButton: "",
};
const AlertProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const toggleAlert = (data) =>
    data ? setState({ ...initialState, ...data }) : setState(initialState);
  const value = {
    state,
    toggleAlert,
  };
  return (
    <AlertContext.Provider value={value}>
      {children}
      <Alert />
    </AlertContext.Provider>
  );
};

export default AlertProvider;
