import styled from "styled-components/macro";
//import { styled as MuiStyled } from "@mui/material/styles";

export const Container = styled.div`
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.875rem 0 4rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 5;
  // width: 100vw;

  .brand {
    font-family: "Raleway";
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--hex-four);
    display: flex;
    align-items: center;
    height: 100%;
    & > img {
      height: 130px;
    }
  }

  .burger-icon {
    display: none;
  }

  @media (max-width: 900px) {
    padding: 0 2rem;
    .burger-icon {
      display: block;
    }
  }

  @media (max-width: 600px) {
    padding: 0 1rem;
    // width: fit-content;
    .message,
    .notification,
    .nameText {
      display: none;
    }
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;

  .msgIcon {
  }
  .bellIcon {
    margin-left: 23px;
  }
  .name {
    display: flex;
    margin-left: 40px;
    align-items: center;
    .userImage {
      padding: 2px;
      margin-right: 12px;
      border-radius: 50%;
      border: 0.5px solid grey;
      height: 36px;
      width: 36px;
    }
    .nameText {
      margin-right: 12px;
    }
  }
`;

// const CustomizedSlider = MuiStyled(Slider)`
//   color: #20b2aa;

//   :hover {
//     color: #2e8b57;
//   }
// `;
